const allowedAttrs = [
  "accesskey",
  "charset",
  "cite",
  "colspan",
  "datetime",
  "dir",
  "headers",
  "hidden",
  "href",
  "hreflang",
  "itemprop",
  "itemtype",
  "itemscope",
  "lang",
  "open",
  "rel",
  "reversed",
  "rowspan",
  "role",
  "scope",
  "span",
  "start",
  "summary",
  "tabindex",
  "title",
  "translate",
  "value",
];

export { allowedAttrs };
