// Empty or Void element tags dont have content
// https://developer.mozilla.org/en-US/docs/Glossary/empty_element
const voidTags = [
  "AREA",
  "BASE",
  "BR",
  "COL",
  "EMBED",
  "HR",
  "IMG",
  "INPUT",
  "LINK",
  "META",
  "PARAM",
  "SOURCE",
  "TRACK",
  "WBR",
  "KEYGEN",
  "COMMAND",
];

const whiteTags = {
  root: ["HTML"],
  metadata: ["BASE", "HEAD", "TITLE"],
  section: [
    "BODY",
    "ADDRESS",
    "ARTICLE",
    "ASIDE",
    "FOOTER",
    "HEADER",
    "H1",
    "H2",
    "H3",
    "H4",
    "H5",
    "H6",
    "HGROUP",
    "MAIN",
    "NAV",
    "SECTION",
  ],
  textBlock: [
    "BLOCKQUOTE",
    "DD",
    "DIV",
    "DL",
    "DT",
    "FIGCAPTION",
    "FIGURE",
    "HR",
    "LI",
    "OL",
    "P",
    "PRE",
    "UL",
  ],
  textInline: [
    "A",
    "ABBR",
    "B",
    "BDI",
    "BDO",
    "BDL",
    "BR",
    "CITE",
    "CODE",
    "DATA",
    "DFN",
    "EM",
    "I",
    "KBD",
    "MARK",
    "Q",
    "S",
    "SAMP",
    "SMALL",
    "SPAN",
    "STRONG",
    "SUB",
    "SUP",
    "TIME",
    "U",
    "VAR",
    "WBR",
    "DEL",
    "INS",
  ],
  tableBlock: ["TABLE"],
  tableInline: [
    "CAPTION",
    "COL",
    "COLGROUP",
    "TBODY",
    "TD",
    "TFOOT",
    "TH",
    "THEAD",
    "TR",
  ],
  media: [],
  script: ["NOSCRIPT"],
  embed: [],
  form: [],
  component: ["DETAILS", "SUMMARY"],
  deprecated: ["CENTER", "MARQUEE", "FONT"],
  deprecatedInline: ["ACRONYM", "BIG", "STRIKE"],
};

/* For reference only, not used */
const blackTags = {
  root: [],
  metadata: ["LINK", "META", "SYTLE"],
  content: [],
  textBlock: [],
  textInline: ["RB", "RP", "RT", "RTC", "RUBY"],
  media: ["AREA", "AUDIO", "IMG", "MAP", "TRACK", "VIDEO"],
  script: ["CANVAS", "SCRIPT"],
  embed: ["EMBED", "IFRAME", "OBJECT", "PARAM", "PICTURE", "SOURCE"],
  form: [
    "BUTTON",
    "DATALIST",
    "FIELDSET",
    "FORM",
    "INPUT",
    "LABEL",
    "LEGEND",
    "METER",
    "OPTGROUP",
    "OPTION",
    "OUTPUT",
    "PROGRESS",
    "SELECT",
    "TEXTAREA",
  ],
  component: ["DIALOG", "MENU", "TEMPLATE", "SLOT"],
  deprecated: [
    "APPLET",
    "BASEFONT",
    "BGSOUND",
    "BLINK",
    "COMMAND",
    "CONTENT",
    "DIR",
    "ELEMENT",
    "FRAME",
    "FRAMESET",
    "IMAGE",
    "ISINDEX",
    "KEYGEN",
    "LISTING",
    "MENUITEM",
    "MULTICOL",
    "NEXTID",
    "NOBR",
    "NOEMBED",
    "NOFRAMES",
    "PLAINTEXT",
    "SHADOW",
    "SPACER",
    "TT",
    "XMP",
  ],
};

const allowedTags = [];
for (let key of Object.keys(whiteTags)) {
  allowedTags.push(...whiteTags[key]);
}

const inlineTags = [
  ...whiteTags["textInline"],
  ...whiteTags["tableInline"],
  ...whiteTags["deprecatedInline"],
];

export { allowedTags, voidTags, inlineTags };
