import "./style.css";
import { fetchHTML, getUrl } from "./fetch.js";
import { pure } from "./pure.js";
import { perfMarkStart, perfMarkEnd, perfMeasure } from "./time";
// import { test } from "./test.js";

// test();

//const workerURL = "https://browse.amitkaps.workers.dev/";
const workerURL = "https://pl41n.amitkaps.workers.dev/";

// const windowId = "tb-window";
// const formId = "#tb-search";
// const inputId = "#tb-search-input";
// const displayId = "#tb-display";
// const fullscreenId = "#tb-fullscreen";

let defaultHtml = `
<h2>A plain view for any website.</h2>
<p> Gets the markup for a website and shows a plain version. Just the text. No images. No styles. No forms. No scripts. </p>
<p> Enter a website address above to see the result. It should work best with a text heavy website and naturally not be useful for any interactive website. You can click <code>#</code> to reset the page and click <code>+</code> to go full screen.</p>
<p>PL41N is pronounced as <em>plain</em> and is an experimental project. All processing is done locally on your browser, except fetching the html page from a cloud worker. Feel free to use the site, but be mindful that there is a daily limit of 100k links.</p>
<p> <small>Handcrafted by <a href="https://amitkaps.com">Amit Kapoor</a></small></p>`;

// Event Handlers

// State Management
const initState = {
  query: "",
  title: "",
  date: "",
  url: "",
  hash: "",
  status: false,
  html: defaultHtml,
  text: "",
  json: "",
};

async function action(type, query = null) {
  let state = {
    query: "",
    title: "",
    date: "",
    url: "",
    hash: "",
    status: false,
    html: defaultHtml,
    text: "",
    json: "",
  };

  switch (type) {
    case "push": {
      if (query) state = await browse(query);
      history.pushState(
        state,
        `PL41N | ${state.title}`,
        `/search?q=${state.hash}`
      );
      break;
    }
    case "replace": {
      let queryLink = null;
      if (query) {
        state = await browse(query);
        queryLink = `/search?q=${state.hash}`;
      }
      history.replaceState(state, `PL41N | ${state.title}`, queryLink);
      break;
    }
    case "pop": {
      if (event.state) state = event.state;
      break;
    }
  }

  // View Update
  tbWeb.innerHTML = state.html;

  let preText = document.createElement("PRE");
  preText.textContent = state.text;
  tbText.innerHTML = "";
  tbText.appendChild(preText);

  let preHtml = document.createElement("PRE");
  preHtml.textContent = state.html;
  tbHtml.innerHTML = "";
  tbHtml.appendChild(preHtml);

  let preJson = document.createElement("PRE");
  preJson.textContent = state.json;
  tbJson.innerHTML = "";
  tbJson.appendChild(preJson);

  tbInput.value = state.url ? state.url : state.query;

  // Expose State
  window.PL41N = state;
}

// Browse Function
async function browse(query) {
  // console.log("Browse");
  let state = initState;
  state["query"] = query;

  perfMarkStart("Fetch");
  // Fetch Raw HTML
  let [url, hash, status, text] = await fetchHTML(query, workerURL);
  perfMarkEnd("Fetch");
  state["url"] = url;
  state["hash"] = hash;
  state["status"] = status;
  state["html"] = text;

  // Get Clean data
  perfMarkStart("Clean");
  if (status) {
    let data = pure(text, url);
    state["html"] = data.html;
    state["title"] = data.title;
    state["date"] = data.date;
    state["text"] = data.text;
    state["json"] = data.json;
  }
  perfMarkEnd("Clean");

  // console.log(state);
  perfMeasure(["Fetch", "Clean"]);

  return state;
}

// UI Interaction
const tbForm = document.querySelector("#tb-search");
const tbInput = document.querySelector("#tb-input");
const tbWeb = document.querySelector("#tb-web");
const tbText = document.querySelector("#tb-text");
const tbHtml = document.querySelector("#tb-html");
const tbJson = document.querySelector("#tb-json");
const resetButton = document.querySelector("#tb-reset");

// Window Load handler
function loadHandler(event) {
  // console.log("load");
  let query = new URLSearchParams(window.location.search).get("q");
  tbInput.value = query;
  action("replace", query);
}

// Window Pop State Handler
async function popHandler(event) {
  // console.log("pop")
  action("pop");
}

// Submit Handlers
function submitHandler(event) {
  // console.log("submit");
  event.preventDefault();
  let query = tbInput.value;
  action("push", query);
}

// Click Handler
// find first parent with "A"
function findParent(tag, element) {
  while (element) {
    if ((element.nodeName || element.tagName) === tag) return element;
    element = element.parentNode;
  }
  return null;
}

function clickHandler(event) {
  // console.log("click");
  event.preventDefault();
  let elementA = findParent("A", event.target);
  if (elementA) {
    let query = elementA.getAttribute("href");
    action("push", query);
  }
}

function resetHandler(event) {
  // console.log("resetting");
  action("push", null);
}

// Handle events
window.addEventListener("load", loadHandler, false);
window.addEventListener("popstate", popHandler, false);
tbForm.addEventListener("submit", submitHandler, false);
tbWeb.addEventListener("click", clickHandler, false);
// resetButton.addEventListener("click", resetHandler, false);
