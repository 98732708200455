const ELEMENT_NODE = 1;
const TEXT_NODE = 3;
const CDATA_SECTION_NODE = 4;
const PROCESSING_INSTRUCTION_NODE = 7;
const COMMENT_NODE = 8;
const DOCUMENT_NODE = 9;
const DOCUMENT_TYPE_NODE = 10;
const DOCUMENT_FRAGMENT_NODE = 11;

function transform(value) {
  const node = value || {};

  switch (node.nodeType) {
    case ELEMENT_NODE:
      return element(node);
    case DOCUMENT_NODE:
    case DOCUMENT_FRAGMENT_NODE:
      return root(node);
    case TEXT_NODE:
      return text(node);
    case COMMENT_NODE:
      return comment(node);
    case DOCUMENT_TYPE_NODE:
      return doctype(node);
    default:
      return null;
  }
}

function root(node) {
  return { type: "root", children: all(node) };
}

function element(node) {
  const tagName = node.tagName;
  const content = node;
  const attributes = node.getAttributeNames();
  const { length } = attributes;
  const props = {};
  let index = 0;

  while (index < length) {
    const key = attributes[index];
    props[key] = node.getAttribute(key);
    index += 1;
  }

  return h(tagName, props, all(content));
}

function h(type, props, ...children) {
  return { type, props, children };
}

function all(node) {
  const nodes = node.childNodes;
  const { length } = nodes;
  const children = [];
  let index = 0;

  while (index < length) {
    const child = transform(nodes[index]);

    if (child !== null) {
      children.push(child);
    }

    index += 1;
  }

  return children;
}

function text(node) {
  return { type: "text", value: node.nodeValue };
}

function comment(node) {
  return { type: "comment", value: node.nodeValue };
}

function doctype(node) {
  return {
    type: "doctype",
    name: node.name || "",
  };
}

function createAST(node) {
  return transform(node) || { type: "root", children: [] };
}

export { createAST };
