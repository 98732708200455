const markStart = "Start";
const markEnd = "End";

function marker(proc, status) {
  return "tb" + proc + status;
}

function perfMarkStart(proc) {
  let markName = marker(proc, markStart);
  return performance.mark(markName);
}

function perfMarkEnd(proc) {
  let markName = marker(proc, markEnd);
  return performance.mark(markName);
}

function perfMeasure(procs) {
  for (const proc of procs) {
    performance.measure(proc, marker(proc, markStart), marker(proc, markEnd));
  }

  // Pull out & log all the measurements.
  let measures = performance.getEntriesByType("measure");
  console.log("Speed");
  for (const m of measures) {
    console.log(`${m.name}: ${m.duration}ms`);
  }

  //Finally, clean up the entries.
  performance.clearMarks();
  performance.clearMeasures();
}

export { perfMarkStart, perfMarkEnd, perfMeasure };
