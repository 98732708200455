//Sanitize the input string
// function _sanitize(str) {
//   let temp = document.createElement("div");
//   temp.textContent = str;
//   let cleanStr = temp.innerHTML;
//   return cleanStr;
// }

//function _validate(url) {
//   const i = document.createElement("input");
//   i.type = "url";
//   i.value = url;
//   return i.validity.valid;
// }

const _matchProtocol = new RegExp(/^https?:\/\//, "i");
const _matchDot = new RegExp(/[\.]/, "i");
const _matchSpace = new RegExp(/[ ]/, "i");

function _validate(url) {
  try {
    let u = new URL(url);
    if (u.username != "" || u.password != "" || u.port != "") return false;
    if (!_matchDot.test(u.origin)) return false;
    if (u.protocol === "http:" || u.protocol === "https:") return true;
    else return false;
  } catch {
    return false;
  }
}

function queryType(str) {
  let type = {};

  type.noProtocol = _matchProtocol.test(str) ? false : true;
  type.noDot = _matchDot.test(str) ? false : true;
  type.noSpace = _matchSpace.test(str) ? false : true;

  return type;
}

const errorQuery = (queryUrl) =>
  `<p> Entered text <code>${queryUrl}</code> does not appear to be a valid website address. Please try again with a different one.`;

async function fetchHTML(query, workerURL) {
  let str = query;
  // console.log(str);
  let url = str; //rewrite query to a proper url
  let status = false;
  let text = "";
  let hash = encodeURIComponent(str);

  let type = queryType(str);
  // console.log(type);
  if (type.noSpace) {
    if (type.noDot) url = `${str}.com`;
    if (type.noProtocol) url = `http://${url}`;
    status = _validate(url) ? true : false;
    url = status ? encodeURI(url) : null;
  } else {
    url = null;
  }

  if (status) {
    let urlhash = encodeURIComponent(url);
    let request = `${workerURL}?q=${urlhash}`;
    let response = await fetch(request);
    // console.log(response);
    text = await response.text();
  } else {
    text = errorQuery(query);
  }
  // console.log(query, url, hash, status, text);
  return [url, hash, status, text];
}

export { fetchHTML };
